<template>
  <div class="applyInquiry">
    <strong>＜入会手続きの問い合わせ先＞</strong>
    <em>公益社団法人日本監査役協会 管理部（入会担当） TEL：03-5219-6100</em>
    <p>お問い合せフォームもご利用ください。</p>
    <a href="#" class="btn green">お問い合わせ</a>
  </div>
</template>

<script>
export default {
  name: "ApplyInquiry",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.applyInquiry {
  border-color: rgba(0, 0, 0, 0.3);
  border-style: solid;
  border-width: 1px;
  margin-bottom: 6rem;
  padding: 2.375rem 0;
  text-align: center;
  strong {
    color: #2c893a;
    display: block;
    font-size: 1.1875rem;
  }
  em {
    display: block;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 700;
  }
  strong,
  em,
  p {
    margin-bottom: 0.5em;
  }
}
@media screen and (max-width: 1100px) {
  .applyInquiry {
    padding: 2.375rem 1.2rem;
  }
}
</style>
