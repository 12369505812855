<template>
  <h3 class="Heading3">
    テーマ：監査役就任一年目を振り返って－新任の皆様へのメッセージも込めて－（h3)
  </h3>
</template>

<script>
export default {
  name: "Heading3",
  props: {
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.Heading3 {
  color: #2c893a;
  font-size: 1.1875rem;
  margin-bottom: 1em;
}
@media screen and (max-width: 1100px) {
  .Heading3 {
    font-size: 1rem;
  }
}
</style>
