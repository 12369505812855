<template>
  <div>
    <dl class="boxContent">
      <dt>ボックス パターン1</dt>
      <dd>
        テキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミー。
      </dd>
    </dl>
    <dl class="boxContent noborder">
      <dt>ボックス パターン2</dt>
      <dd>
        テキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミー。
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: "BoxContent",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.boxContent {
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 3rem;
  padding: 2rem;
  dt {
    color: #2c893a;
    font-size: 1.1875rem;
    font-weight: 700;
    margin-bottom: 1em;
  }
  dd {
    font-weight: 300;
    line-height: 1.8;
  }
  &.noborder {
    background-color: rgba(44, 137, 58, 0.1);
    border: none;
  }
}
@media screen and (max-width: 1100px) {
  .boxContent {
    dt {
      font-size: 1rem;
    }
    dd {
      font-size: 0.8125rem;
    }
    &.noborder {
      background-color: rgba(44, 137, 58, 0.1);
      border: none;
    }
  }
}
</style>
