<template>
  <div>
    <div class="tableWrap">
      <h2 class="Heading2">
        実務部会（h2）
      </h2>
      <table class="scheduleList">
        <thead>
          <tr>
            <th colspan="2">&nbsp;</th>
            <th class="title">2月の開催内容</th>
            <th>3月</th>
            <th>4月</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="3">
              製造業<br />
              監査実務部会
            </td>
            <td>
              第1部会
            </td>
            <td>
              アンケートに基づくディスカッション「新型コロナウイルス感染症影響下での監査活動」（22日※オンライン開催）
            </td>
            <td>
              3月24日(水)
            </td>
            <td>
              4月20日(火)
            </td>
          </tr>
          <tr>
            <td>
              第2部会
            </td>
            <td>
              「私の監査活動」アルファ 大井信幸氏（22日※オンライン開催）
            </td>
            <td>
              3月23日(火)
            </td>
            <td>
              4月16日(金)
            </td>
          </tr>
          <tr>
            <td>
              第3部会
            </td>
            <td>
              「私の監査活動」前澤工業 大河原昭男氏（3日※オンライン開催）
            </td>
            <td>
              3月25日(木)
            </td>
            <td>
              4月16日(金)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h2 class="Heading2">
      製造業監査実務部会
    </h2>
    <table class="scheduleList recompose">
      <thead>
        <tr>
          <th class="title" colspan="2">2月の開催内容</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            第1部会
          </td>
          <td>
            アンケートに基づくディスカッション「新型コロナウイルス感染症影響下での監査活動」（22日※オンライン開催）
          </td>
        </tr>
        <tr>
          <td>
            第2部会
          </td>
          <td>
            「私の監査活動」アルファ 大井信幸氏（22日※オンライン開催）
          </td>
        </tr>
        <tr>
          <td>
            第3部会
          </td>
          <td>
            「私の監査活動」前澤工業 大河原昭男氏（3日※オンライン開催）
          </td>
        </tr>
        <tr>
          <td>
            第4部会
          </td>
          <td>
            「社外取締役の役割、社外監査役の役割、連携」大崎電気工業
            堀長一郎氏/「内部統制システムに係る監査役監査（実施基準への対応含む）」長瀬産業
            古川方理氏（5日※オンライン開催）
          </td>
        </tr>
        <tr>
          <td>
            第5部会
          </td>
          <td>
            「キリングループのリスクマネジメント」キリンホールディングス
            桑田啓二氏（3日※オンライン開催）
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ScheduleList",
  components: {},
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.scheduleList {
  th,
  td {
    border-right-color: #ccc;
    border-right-style: solid;
    border-right-width: 1px;
    &:last-child {
      border-right-width: 0;
    }
  }
  td {
    font-size: 0.9375rem;
    padding: 1rem;
    &[rowspan] {
      text-align: center;
    }
  }
  .title {
    width: 40%;
  }
  &.recompose {
    margin-bottom: 3rem;
    td {
      &:first-child {
        background-color: #f2f2f2;
        text-align: center;
      }
      &:last-child {
        width: 80%;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .scheduleList {
    td {
      font-size: 0.8125rem;
    }
  }
}
</style>
