<template>
  <div>
    <ul class="tagList">
      <li class="large"><a href="#">#会計監査</a></li>
      <li><a href="#">#内部統制</a></li>
      <li><a href="#">#基準・規則・ひな形</a></li>
      <li><a href="#">#指名委員会等設置会社</a></li>
      <li><a href="#">#監査等委員会設置会社</a></li>
      <li><a href="#">#新任ガイド・実施要領</a></li>
      <li><a href="#">#海外関連</a></li>
      <li><a href="#">#監査業務支援ツール</a></li>
      <li><a href="#">#コーポレート・ガバナンス</a></li>
      <li><a href="#">#インターネットアンケート</a></li>
      <li><a href="#">#その他調査結果</a></li>
      <li><a href="#">#中小規模会社支援</a></li>
      <li><a href="#">#企業不祥事</a></li>
      <li><a href="#">#報告書</a></li>
      <li><a href="#">#監査役スタッフ</a></li>
      <li><a href="#">#法務事務局</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TagList",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3rem;
  .large {
    font-size: 1.3125rem;
  }
  li {
    font-size: 0.875rem;
    margin-bottom: 0.5em;
    margin-right: 1em;
  }
  a {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    border-radius: 1em;
    color: rgba(0, 0, 0, 0.6);
    display: inline-block;
    line-height: 2em;
    padding: 0 1em;
  }
}
@media screen and (max-width: 1100px) {
  .tagList {
    .large {
      font-size: 1.0625rem;
    }
    li {
      font-size: 0.75rem;
    }
  }
}
</style>
