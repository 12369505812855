<template>
  <ul class="contentList">
    <li>
      <div class="infoBlock">
        <a href="#" class="title">
          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
        </a>
        <ul class="tagList">
          <li><a href="#">#内部統制</a></li>
          <li><a href="#">#基準・規則・ひな形</a></li>
          <li><a href="#">#指名委員会等設置会社</a></li>
          <li><a href="#">#監査等委員会設置会社</a></li>
          <li><a href="#">#新任ガイド・実施要領</a></li>
          <li><a href="#">#海外関連</a></li>
        </ul>
        <span class="date">発行：0000年0月00日</span>
      </div>
    </li>
    <li>
      <div class="infoBlock">
        <a href="#" class="title">
          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
        </a>
        <ul class="tagList">
          <li><a href="#">#内部統制</a></li>
          <li><a href="#">#基準・規則・ひな形</a></li>
          <li><a href="#">#指名委員会等設置会社</a></li>
          <li><a href="#">#監査等委員会設置会社</a></li>
          <li><a href="#">#新任ガイド・実施要領</a></li>
          <li><a href="#">#海外関連</a></li>
        </ul>
        <span class="date">発行：0000年0月00日</span>
      </div>
    </li>
    <li>
      <div class="infoBlock">
        <a href="#" class="title">
          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
        </a>
        <ul class="tagList">
          <li><a href="#">#指名委員会等設置会社</a></li>
          <li><a href="#">#監査等委員会設置会社</a></li>
          <li><a href="#">#新任ガイド・実施要領</a></li>
          <li><a href="#">#海外関連</a></li>
        </ul>
        <span class="date">発行：0000年0月00日</span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ContentList",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.contentList {
  border-top-color: #ccc;
  border-top-style: solid;
  border-top-width: 1px;
  margin-bottom: 3rem;
  > li {
    border-bottom-color: #ccc;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 0.5rem 0;
  }
  .infoBlock {
    border-left-color: #2c893a;
    border-left-style: solid;
    border-left-width: 5px;
    padding: 1.25rem;
  }
  .title {
    display: block;
    font-weight: 500;
    margin-bottom: 1em;
  }
  .tagList {
    margin-bottom: 0;
  }
  .date {
    color: #2c893a;
    display: block;
    font-weight: 350;
  }
}
</style>
