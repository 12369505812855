<template>
  <h2 class="Heading2">
    2018年8月1日に、「監査役監査の基礎知識 自己診断」を<br />
    リニューアルいたしました。（h2）
  </h2>
</template>

<script>
export default {
  name: "Heading2",
  props: {
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.Heading2 {
  color: #2c893a;
  font-size: 1.375rem;
  margin-bottom: 1em;
}
@media screen and (max-width: 1100px) {
  .Heading2 {
    font-size: 1.125rem;
  }
}
</style>
