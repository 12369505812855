<template>
  <ul class="buttonList">
    <li>
      <a href="#" class="btn green">リンクボタン</a>
    </li>
    <li>
      <a href="#" class="btn green outline">リンクボタン</a>
    </li>
    <li class="lSize">
      <a href="#" class="btn green L">リンクボタン</a>
    </li>
    <li class="lSize">
      <a href="#" class="btn green outline L">リンクボタン</a>
    </li>
    <li>
      <a href="#" class="btn green S">リンクボタン</a>
    </li>
    <li>
      <a href="#" class="btn green outline S">リンクボタン</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ButtonList",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.buttonList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 3rem;
  li {
    margin-bottom: 1rem;
  }
  .lSize {
    flex-basis: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 1100px) {
  .buttonList {
    justify-content: center;
    li {
      margin: 0 2% 1rem;
    }
  }
}
</style>
