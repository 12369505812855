<template>
  <nav id="sidenav">
    <dl>
      <dt>監査実務支援</dt>
      <dd>
        <a href="#" class="current"><span>電子図書館</span></a>
      </dd>
      <dd>
        <a href="#"><span>監査トピックス</span></a>
      </dd>
      <dd>
        <a href="#"
          ><span>監査役監査の基礎知識<br />（自己診断）</span></a
        >
      </dd>
      <dd>
        <a href="#"><span>監査役インタビュー</span></a>
      </dd>
      <dd>
        <a href="#"><span>対談・座談会</span></a>
      </dd>
    </dl>
  </nav>
</template>

<script>
export default {
  name: "SideNav",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#sidenav {
  flex-basis: 250px;
  dt {
    background-color: #2c883a;
    color: #fff;
    font-weight: 700;
    line-height: 3.25rem;
    text-align: center;
  }
  dd {
    border-bottom-color: #b2b2b2;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  a {
    background-image: url(/static/img/arrowSideNav.svg);
    background-position: 90% center;
    background-repeat: no-repeat;
    background-size: 6px auto;
    display: block;
    &::before {
      content: "";
      display: inline-block;
      height: 3.875rem;
      vertical-align: middle;
      width: 1em;
    }
    &.current {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
}
@media screen and (max-width: 1100px) {
  #sidenav {
    display: none;
  }
}
</style>
