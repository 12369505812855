<template>
  <ul class="linkCard">
    <li>
      <a href="#">
        <img src="https://via.placeholder.com/360x180" alt="" />
        <div class="info">
          <strong>監査役監査の基礎</strong>
          <span
            >法律・会計等に関する研修会、講演会の開催予定です。会員会社以外の皆様にもご受講いただけます。</span
          >
        </div>
      </a>
    </li>
    <li>
      <a href="#">
        <img src="https://via.placeholder.com/360x180" alt="" />
        <div class="info">
          <strong>監査役監査の基礎</strong>
          <span
            >法律・会計等に関する研修会、講演会の開催予定です。会員会社以外の皆様にもご受講いただけます。</span
          >
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "LinkCard",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.linkCard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  li {
    flex-basis: 22.5rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
  .info {
    padding: 1rem 1.5rem;
    strong {
      color: #2c893a;
      display: block;
      font-size: 1.1875rem;
      margin-bottom: 0.5rem;
    }
    span {
      display: block;
      font-size: 0.9375rem;
      font-weight: 300;
    }
  }
}
@media screen and (max-width: 1100px) {
  .linkCard {
    flex-wrap: wrap;
    li {
      margin-bottom: 2rem;
    }
  }
  .info {
    strong {
      font-size: 1rem;
    }
    span {
      font-size: 0.8125rem;
    }
  }
}
</style>
