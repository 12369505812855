<template>
  <div class="pageImageTitle"><!--<strong>電子図書館（h1）</strong>--></div>
</template>

<script>
export default {
  name: "PageImageTitle",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.pageImageTitle {
  background-image: url(/static/img/temp/styleTitle.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 2.125rem;
  line-height: 1.5;
  margin-bottom: 2.5rem;
  padding-bottom: 16.6%;
  position: relative;
  strong {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -550px;
    margin-top: -0.75em;
  }
}
@media screen and (max-width: 1100px) {
  .pageImageTitle {
    font-size: 1.5rem;
    padding-bottom: 53.3%;
    strong {
      margin-left: -45vw;
    }
  }
}
</style>
