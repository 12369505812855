<template>
  <ul class="linkBoxList">
    <li class="L">
      <a href="#" class="card">
        <strong>各種ひな型のWord、Excelファイル（会員専用）はこちら</strong>
        <span
          >テキストテキストテキストテキストテキストテキストテキストテキスト</span
        >
      </a>
    </li>
    <li class="S">
      <a href="#" class="card">
        <strong>共 通</strong>
        <span>テキストテキストテキストテキストテキストテキストテキ</span>
      </a>
    </li>
    <li class="S">
      <a href="#" class="card">
        <strong>
          監査役（会）<br />
          設置会社
        </strong>
        <span>テキストテキストテキストテキストテキストテキストテキ</span>
      </a>
    </li>
    <li class="S">
      <a href="#" class="card">
        <strong>監査等委員会 設置会社</strong>
        <span>テキストテキストテキストテキストテキストテキストテキ</span>
      </a>
    </li>
    <li class="M">
      <a href="#" class="card">
        <strong><i>A</i>会員登録の手続きについて</strong>
        <span
          >入会、登録内容変更、退会等に関する手続きについてのお問い合わせはこちら
        </span>
      </a>
    </li>
    <li class="M">
      <a href="#" class="card">
        <strong
          ><i>B</i>登録会員の方の監査実務に<br class="onlyPC" />
          関するご相談(Net相談室)
        </strong>
        <span
          >入会、登録内容変更、退会等に関する手続きについてのお問い合わせはこちら
        </span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "LinkBoxList",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.linkBoxList {
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 3rem;
  padding: 2rem;
  li {
    margin-bottom: 1.625rem;
    text-align: center;
    &.S {
      flex-basis: 30%;
      strong {
        height: 3em;
      }
    }
    &.M {
      flex-basis: 48%;
      strong {
        height: 3em;
        margin-left: 2em;
        text-align: left;
        text-indent: -2em;
      }
    }
    &.L {
      flex-basis: 100%;
    }
  }
  a {
    background-color: #fff;
    display: block;
    padding: 2rem;
    position: relative;
    text-align: center;
    &:before {
      border-top: 10px solid #2c893a;
      border-bottom: 10px solid transparent;
      border-right: 10px solid transparent;
      border-left: 10px solid #2c893a;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  strong {
    display: inline-block;
    font-size: 1.1875rem;
    margin-bottom: 0.5em;
    i {
      background-color: #2c893a;
      color: #fff;
      display: inline-block;
      font-style: normal;
      margin-right: 0.5em;
      text-align: center;
      text-indent: 0;
      width: 1.5em;
    }
  }
  span {
    display: block;
    font-size: 0.9375rem;
    font-weight: 300;
  }
}
@media screen and (max-width: 1100px) {
  .linkBoxList {
    li {
      &.S {
        flex-basis: 100%;
        strong {
          height: auto;
        }
      }
      &.M {
        flex-basis: 100%;
        strong {
          height: auto;
          text-align: left;
        }
      }
      &.L {
        flex-basis: 100%;
        strong {
          height: auto;
        }
      }
    }
  }
}
</style>
