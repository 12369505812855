<template>
  <ul class="linkList">
    <li>PDFリンク<a href="#" class="link pdf">> PDFアイコン</a></li>
    <li>文中リンクテキスト<a href="#" class="link">文中リンクテキスト</a></li>
    <li>単独リンクテキスト<a href="#" class="link">>リンクテキスト</a></li>
    <li>
      PPT(パワーポイント）リンク<a href="#" class="link ppt"
        >>リンクテキストリンクテキスト</a
      >
    </li>
    <li>e-bookリンク<a href="#" class="link ebook">> e-bookアイコン</a></li>
    <li>
      別ウインドウリンク<a href="#" class="link" target="_blank"
        >別ウインドウアイコン</a
      >
    </li>
    <li>Acrobat Reader<a href="#" class="link adobe">> Acrobat Reader</a></li>
  </ul>
</template>

<script>
export default {
  name: "LinkList",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.linkList {
  margin-bottom: 3rem;
  li {
    margin-bottom: 1em;
  }
  a {
    margin-left: 3em;
  }
}
</style>
