<template>
  <div class="tableWrap">
    <table class="downloadList">
      <thead>
        <tr>
          <th class="title">タイトル</th>
          <th>発行日</th>
          <th>ダウンロード</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
          </td>
          <td class="date">
            0000年00月00日
          </td>
          <td class="downloadLink">
            <a href="#"><img src="/static/img/icon/pdfDownload.png"/></a>
          </td>
        </tr>
        <tr>
          <td>
            テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
          </td>
          <td class="date">
            0000年00月00日
          </td>
          <td class="downloadLink">
            <a href="#"><img src="/static/img/icon/pdfDownload.png"/></a>
          </td>
        </tr>
        <tr>
          <td>
            テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
          </td>
          <td class="date">
            0000年00月00日
          </td>
          <td class="downloadLink">
            <a href="#"><img src="/static/img/icon/pdfDownload.png"/></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DownloadList",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.downloadList {
}
</style>
