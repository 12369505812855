<template>
  <div class="content">
    <ol class="breadCrumb">
      <li><a href="/">ホーム</a></li>
      <li><a href="/">監査実務支援</a></li>
      <li><a href="/">電子図書館</a></li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.breadCrumb {
  display: flex;
  padding: 0.65em 0;
  li {
    font-size: 0.8125rem;
    &:first-child {
      a {
        background-image: url(/static/img/icon/home.svg);
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 1.125rem auto;
        padding-left: 1.5rem;
      }
    }
    &:last-child {
      &::after {
        content: "";
        margin-right: 0;
      }
    }
    &::after {
      content: ">";
      margin-right: 0.5em;
    }
    a {
      margin-right: 0.5em;
    }
  }
}
</style>
