<template>
  <ul class="pagination">
    <li><a href="#">&lt;</a></li>
    <li><a href="#" class="current">1</a></li>
    <li><a href="#">2</a></li>
    <li><a href="#">3</a></li>
    <li><a href="#">...</a></li>
    <li><a href="#">98</a></li>
    <li><a href="#">99</a></li>
    <li><a href="#">100</a></li>
    <li><a href="#">&gt;</a></li>
  </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  li {
    margin-left: 0.25rem;
    &:first-child {
      margin-left: 0;
    }
  }
  a {
    border-color: rgba(0, 0, 0, 0.41);
    border-style: solid;
    border-width: 1px;
    color: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    display: block;
    line-height: 30px;
    width: 30px;
    text-align: center;
    &.current {
      background-color: rgba(0, 0, 0, 0.41);
      border-color: transparent;
      color: #fff;
    }
  }
}
</style>
