<template>
  <div class="styleguide">
    <BreadCrumb />
    <PageImageTitle />
    <div class="content withSideNav">
      <SideNav />
      <div class="contentS">
        <Heading1 title="電子図書館（h1）" />
        <p class="lead">
          「監査役監査基準」等の行動指針、委員会・研究会報告、調査・研究結果など監査役実務に役立つ報告書を所蔵しています。(リード)
        </p>

        <section class="sectionBody">
          <Heading2 />
          <p class="contentBody">
            設問は監査役に関する法律問題を中心に10問で、１ヵ月単位（第１営業日）で更新されます。10問全てに答えた後に解答及び解説が表示されます。<br />
            月に一度のトレーニングとして、ぜひチャレンジしていただきたくご案内いたします。なお、リニューアルにあたって、設問・解説等は森・濱田松本法律事務所
            弁護士松井秀樹氏に作成いただきました。この場を借りて御礼申し上げます。（本文）
          </p>
        </section>

        <section class="sectionBody">
          <Heading3 />
          <p class="contentBody">
            多くの会員会社におかれましては、6月の株主総会にて、新たに監査役・監査委員・監査等委員・監事の方々が就任されたことと存じます。<br />
            本号では、就任二年目を迎えられた先輩監査役として、株式会社リクルートホールディングス常勤監査役である長嶋由紀子様にインタビューを行い、新任監査役等の方々に向けて、就任一年目を振り返る形でご経験談をご披露いただくと共に、メッセージを頂戴しました。（本文）
          </p>
          <ul class="noticeList">
            <li>※こちらでは、インタビューの一部をご紹介いたします。</li>
            <li>
              ※全文は月刊監査役673号（2017年10月号）に掲載しております。(注釈）全文は月刊監査役673号（2017年10月号）に掲載しております。(注釈）全文は月刊監査役673号（2017年10月号）に掲載しております。(注釈）
            </li>
          </ul>
          <img
            src="https://via.placeholder.com/165x180"
            class="contentImg"
            alt=""
          />
        </section>

        <CMSArea title="最新ニュース" :contents="newsData" />
        <Pagination />
        <BoxContent />
        <TagList />
        <ContentList />
        <DownloadList />
        <ScheduleList />
        <LinkCard />
        <LinkBoxList />
        <ButtonList />
        <LinkList />
        <ApplyInquiry />
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import PageImageTitle from "@/components/PageImageTitle.vue";
import SideNav from "@/components/SideNav.vue";
import Heading1 from "@/components/Heading1.vue";
import Heading2 from "@/components/Heading2.vue";
import Heading3 from "@/components/Heading3.vue";
import CMSArea from "@/components/CMSArea.vue";
import Pagination from "@/components/Pagination.vue";
import BoxContent from "@/components/BoxContent.vue";
import TagList from "@/components/TagList.vue";
import ContentList from "@/components/ContentList.vue";
import DownloadList from "@/components/DownloadList.vue";
import ScheduleList from "@/components/ScheduleList.vue";
import LinkCard from "@/components/LinkCard.vue";
import LinkBoxList from "@/components/LinkBoxList.vue";
import ButtonList from "@/components/ButtonList.vue";
import ApplyInquiry from "@/components/ApplyInquiry.vue";
import LinkList from "@/components/LinkList.vue";

export default {
  name: "StyleGuide",
  components: {
    BreadCrumb,
    PageImageTitle,
    SideNav,
    Heading1,
    Heading2,
    Heading3,
    CMSArea,
    Pagination,
    BoxContent,
    TagList,
    ContentList,
    DownloadList,
    ScheduleList,
    LinkCard,
    LinkBoxList,
    ButtonList,
    ApplyInquiry,
    LinkList,
  },
  data: function() {
    return {
      newsData: [
        {
          date: "2020年11月12日",
          title: "第47回定時会員総会・第214回理事会を開催",
          tag: "info",
          recent: true,
        },
        {
          date: "2020年11月11日",
          title: "システム不具合に伴うサービス利用停止について",
          tag: "info",
          recent: true,
        },
        {
          date: "2020年11月4日",
          title:
            "講演会・研修会の動画配信の受講申込期間と視聴期間の変更について",
          tag: "report",
        },
        {
          date: "2020年10月27日",
          title: "第47回定時会員総会招集のご案内",
          tag: "info",
        },
        {
          date: "2020年10月26日",
          title: "「月刊監査役」11月号（No.715）を発行",
          tag: "chairman",
        },
        {
          date: "2020年10月23日",
          title:
            "中部支部講演会「監査役監査実務事例（期末監査・その他の監査役監査の事例）監査役監査チェックリストのご紹介」(収録日10月19日）の動画配信のお知らせ（会員以外の方にも有料公開）",
          tag: "press",
        },
      ],
    };
  },
};
</script>
