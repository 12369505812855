<template>
  <h1 class="Heading1">
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: "Heading1",
  props: {
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.Heading1 {
  color: #2c893a;
  font-size: 1.625rem;
  margin-bottom: 1em;
}
</style>
